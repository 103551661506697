<template>
  <div>
    <b-container fluid>
      <!-- <b-row v-if="panelObj.dealerLoader">
        <b-col class="text-center">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
          <br/>
          {{ panelObj.dealerMessage }}
        </b-col>
      </b-row>
      <div v-else class="row">
        <div class="box-message col-md-12 mt-5 text-danger">
            <div class="message-body-center">
                <p class="pb-2 pt-2 h5"><i class="ri-alert-fill mr-2"></i>{{ panelObj.dealerMessage }}</p>
            </div>
        </div>
      </div> -->
      <profile-status-msg :preConditionLoader="panelObj.dealerLoader" :profileStatus="panelObj.dealerMessage === '' ? 4 : 3" :profile-url="''" :inForm="false"/>
      <div v-if="!panelObj.dealerLoader && panelObj.dealerMessage === ''" class="full-body mb-4" style="box-shadow: 0 0 3px rgb(120 146 141 / 50%);background: #fff;">
        <b-card>
            <div class="card-header fertilizer-card-header">
                <div class="iq-header-title text-center">
                    <h4 class="">{{ $t('dealer_panel.requisition_info') }}</h4>
                </div>
            </div>
            <div class="card-body iq-card-body ">
                <div class="b-overlay-wrap position-relative">
                    <div class="row">
                        <div class="col-sm-12">
                          <div class="row mb-2">
                            <b-col sm="4">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="fiscal_year_id"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.fiscalYear')}}<span class="text-danger"></span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  @input="loadData()"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                              </b-form-group>
                            </b-col>
                            <b-col sm="4">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="month_id"
                                >
                                  <template v-slot:label>
                                    {{$t('pusti_mapping.month')}}<span class="text-danger"></span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="search.month_id"
                                    :options="monthList"
                                    id="month_id"
                                    @input="loadData()"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="4">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="allocation_type_id"
                                >
                                  <template v-slot:label>
                                    {{$t('fertilizerSales.allocationType')}}<span class="text-danger"></span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="search.allocation_type_id"
                                    :options="allocationTypeList"
                                    id="allocation_type_id"
                                    @input="loadData()"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                          </div>
                        </div>
                        <!-- <div class="row">
                          <div class="col-12">
                            <hr class="hrclass"/>
                          </div>
                        </div> -->
                        <div class="col-12">
                            <b-overlay :show="loading">
                                <div class="row" aria-hidden="loading | listReload ? 'true' : null" v-if="requisition.length > 0">
                                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" v-for="(req, index) in requisition" :key="'req' + index">
                                        <div class="seeds-box" :class="req.class">
                                            <div class="seeds-box-icon">
                                                <h4 class="text-center text-white"> {{  (i18 === 'bn') ? req.text_bn : req.text_en }}</h4>
                                            </div>
                                            <div class="info text-center">
                                                <!-- <h2>100 M Ton</h2> -->
                                                <div class="d-inline-flex p-2 text-warning text-success"><h2 class="text-white">{{$n(req.total_quantity, { useGrouping: false })}} {{ $t('fertilizerReport.MTon') }}</h2></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="height: 70px" class="panel-body text-center mt-3" v-else>
                                  <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                                </div>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </b-card>
        <b-card>
            <div class="card-header text-center text-white" style="padding-top: 5px; padding-bottom: 5px;background-color: #00cc00 !important;">
                <div class="iq-header-title text-center">
                    <h4 class="">{{ $t('dealer_panel.allocation_info') }}</h4>
                </div>
            </div>
            <div class="card-body iq-card-body ">
                <div class="b-overlay-wrap position-relative">
                    <div class="row">
                        <div class="col-12">
                            <b-overlay :show="loading">
                                <div class="row" aria-hidden="loading | listReload ? 'true' : null" v-if="allocation.length > 0">
                                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" v-for="(req, index) in allocation" :key="'req' + index">
                                        <div class="seeds-box" :class="req.class">
                                            <div class="seeds-box-icon">
                                                <h4 class="text-center text-white"> {{  (i18 === 'bn') ? req.text_bn : req.text_en }}</h4>
                                            </div>
                                            <div class="info text-center">
                                                <!-- <h2>100 M Ton</h2> -->
                                                <div class="d-inline-flex p-2 text-warning text-success"><h2 class="text-white">{{$n(req.total_quantity, { useGrouping: false })}} {{ $t('fertilizerReport.MTon') }}</h2></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="height: 70px" class="panel-body text-center mt-3" v-else>
                                  <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                                </div>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </b-card>
        <b-card>
            <div class="card-header fertilizer-card-header">
                <div class="iq-header-title text-center">
                    <h4 class="">{{ $t('fertilizerConfig.sales_info') }}</h4>
                </div>
            </div>
            <div class="card-body iq-card-body ">
                <div class="b-overlay-wrap position-relative">
                    <div class="row">
                        <div class="col-12">
                            <b-overlay :show="loading">
                                <div class="row" aria-hidden="loading | listReload ? 'true' : null" v-if="salesInfo.length > 0">
                                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4" v-for="(req, index) in salesInfo" :key="'req' + index">
                                        <div class="seeds-box" :class="req.class">
                                            <div class="seeds-box-icon">
                                                <h4 class="text-center text-white"> {{  (i18 === 'bn') ? req.text_bn : req.text_en }}</h4>
                                            </div>
                                            <div class="info text-center">
                                                <!-- <h2>100 M Ton</h2> -->
                                                <div class="d-inline-flex p-2 text-warning text-success"><h2 class="text-white">{{$n(req.total_quantity, { useGrouping: false })}} {{ $t('fertilizerReport.MTon') }}</h2></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="height: 70px" class="panel-body text-center mt-3" v-else>
                                  <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                                </div>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </b-card>
      </div>
    </b-container>
  </div>
</template>
<style scoped>
.fertilizer-card-header{
  color: #fff;
  text-align: center;
  padding: 5px!important;
  background-color: var(--brand-secondary-color)!important;
}
.hrclass {
    border-top: 1px solid var(--brand-color);
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.seeds-box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 0px;
    text-align: right;
    display: block;
    margin-top: 20px;
}
.seeds-box-icon {
    background-color: #609513 !important;
    margin: 0 auto;
    margin-top: -20px;
    font-size: 12px !important;
    height: 40px;
    padding-top: 7px;
}
.seeds-box-icon span {
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info h4 {
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.info > p {
    color: #717171;
    font-size: 16px;
    padding-top: 10px;
    text-align: justify;
}
</style>
<script>
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { mapGetters } from 'vuex'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import { dealerPaneldashboard } from '../../api/routes'

export default {
  components: { ProfileStatusMsg },
  name: 'UiDataTable',
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        month_id: 0,
        allocation_type_id: 0
      },
      loading: false,
      requisition: [],
      allocation: [],
      salesInfo: [],
      todayDelivery: 0,
      currentStock: 0
    }
  },
  computed: {
    ...mapGetters({
      panelObj: 'ExternalUserIrrigation/panelObj'
    }),
    i18 () {
      return this.$i18n.locale
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    fertilizerNameList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    monthList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear
    },
    allocationTypeList: function () {
        const type = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    series () {
      return this.seriesData
    },
    loadData () {
        this.loading = true
        RestApi.getData(seedFertilizerServiceBaseUrl, dealerPaneldashboard, this.search).then(response => {
            if (response.success) {
                const fertilizerNameListR = this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList
                const requisition = fertilizerNameListR.map((element, key) => {
                    const requisitionList = response.data.requisition.find(fertilizer => parseInt(fertilizer.fertilizer_id) === parseInt(element.value))
                    const totalQtyObj = {}
                    totalQtyObj.total_quantity = typeof requisitionList !== 'undefined' ? requisitionList.total_quantity : 0
                    if (key === 0) {
                       totalQtyObj.class = 'bg-secondary'
                    } else if (key === 1) {
                       totalQtyObj.class = 'bg-delivery'
                    } else if (key === 2) {
                       totalQtyObj.class = 'bg-success'
                    }
                    return Object.assign({}, element, totalQtyObj)
                })
                this.requisition = requisition
                const allocation = fertilizerNameListR.map((element, key) => {
                    const requisitionList1 = response.data.allocation.find(fertilizer => parseInt(fertilizer.fertilizer_id) === parseInt(element.value))
                    const totalQtyObj1 = {}
                    totalQtyObj1.total_quantity = typeof requisitionList1 !== 'undefined' ? requisitionList1.total_quantity : 0
                    if (key === 0) {
                       totalQtyObj1.class = 'bg-secondary'
                    } else if (key === 1) {
                       totalQtyObj1.class = 'bg-delivery'
                    } else if (key === 2) {
                       totalQtyObj1.class = 'bg-success'
                    }
                    return Object.assign({}, element, totalQtyObj1)
                })
                this.allocation = allocation
                const salesInfo = fertilizerNameListR.map((element, key) => {
                    const requisitionList2 = response.data.salesInfo.find(fertilizer => parseInt(fertilizer.fertilizer_id) === parseInt(element.value))
                    const totalQtyObj2 = {}
                    totalQtyObj2.total_quantity = typeof requisitionList2 !== 'undefined' ? requisitionList2.total_quantity : 0
                    if (key === 0) {
                       totalQtyObj2.class = 'bg-secondary'
                    } else if (key === 1) {
                       totalQtyObj2.class = 'bg-delivery'
                    } else if (key === 2) {
                       totalQtyObj2.class = 'bg-success'
                    }
                    return Object.assign({}, element, totalQtyObj2)
                })
                this.salesInfo = salesInfo
            }
            this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.bg-delivery {
    background-color: #008082;
}
.hrclass {
    border-top: 1px solid rgb(96, 149, 19) !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.seeds-box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 0px;
    text-align: right;
    display: block;
    margin-top: 20px;
}
.seeds-box-icon {
    background-color: #609513 !important;
    margin: 0 auto;
    margin-top: -20px;
    font-size: 12px !important;
    height: 40px;
    padding-top: 7px;
}
.seeds-box-icon span {
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info h4 {
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.info > p {
    color: #717171;
    font-size: 16px;
    padding-top: 10px;
    text-align: justify;
}
</style>
